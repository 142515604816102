import React from "react";
import axios from "axios";
import { formatDistanceStrict } from "date-fns";
// import DeviceInlets from "../deviceInlets";
import Loading from "../../../components/common/loading";
import DateRangeInput from "../../../components/common/inputs/dateRangeInput";
import LineChart from '../../../components/common/charts/lineChart';

import chartColors from "../../../assets/chartColors";
import RowList from "../../../components/common/rowList";

export default class DevicePageInlet extends React.Component {
  state = {
    inletLogs: [],
    inletLogsLoading: false,
    inletLogsLoaded: false,
    bankLogs: [],
    chartStartDate: (() => {
      const todaysUnixStartTime = new Date();
      todaysUnixStartTime.setHours(0, 0, 0, 0);
      return todaysUnixStartTime.getTime();
    })(), // default to last day
    chartEndDate: Date.now(),
    chartTimeFrameText: "Today",
  };
  grabCsvData = async () => {
    const { enclosureSerialNumber } = this.props.match.params;

    const timeDiff = this.state.chartEndDate - this.state.chartStartDate;
    const granularityInMinutes = Math.round(timeDiff / 1000 / 1000 / 60) || 1;

    await axios({
      url: `/v1/logs/inlets?enclosureSerialNumber=${enclosureSerialNumber}&since=${this.state.chartStartDate}&before=${this.state.chartEndDate}${
        granularityInMinutes > 0
          ? `&granularity=${granularityInMinutes}&outputFormat=csv`
          : ""
      }`, //your url
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "inlet_logs.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
  grabDataSet = async () => {
    const { enclosureSerialNumber } = this.props.match.params;
    const timeDiff = this.state.chartEndDate - this.state.chartStartDate;
    const granularityInMinutes = Math.round(timeDiff / 1000 / 1000 / 60) || 1;

    this.setState({ inletLogsLoading: true })
    const logs = await axios.get(
      `/v1/logs/inlets?enclosureSerialNumber=${enclosureSerialNumber}&since=${ this.state.chartStartDate}&before=${this.state.chartEndDate}${
        granularityInMinutes > 0 ? `&granularity=${granularityInMinutes}` : ""
      }`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    this.setState({ inletLogsLoading: false, inletLogsLoaded: true })
    const foundDevice =
      this.props.resources.devices.find(
        (d) => d.enclosureSerialNumber == enclosureSerialNumber
      ) || {};
    if (foundDevice.numBanks > 1) {
      const bankLogs = await axios.get(
        `/v1/logs/banks?enclosureSerialNumber=${enclosureSerialNumber}&since=${ this.state.chartStartDate}&before=${this.state.chartEndDate}${
          granularityInMinutes > 0 ? `&granularity=${granularityInMinutes}` : ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      if (bankLogs.status === 200) {
        const arrayofBankLogs = bankLogs.data.data
          .reduce((sum, item) => {
            if (sum.find((bankLogs) => bankLogs.bank_id === item.bank_id)) {
              return sum.map((s) =>
                s.bank_id === item.bank_id
                  ? {
                      ...s,
                      items: [item, ...s.items],
                    }
                  : {
                      ...s,
                      items: s.items,
                    }
              );
            } else {
              return [
                ...sum,
                {
                  bank_id: item.bank_id,
                  // bankLines: item.bankLines,
                  items: [item],
                },
              ];
            }
          }, [])
          .sort((a, b) => a.bank_id.localeCompare(b.bank_id));
        this.setState({
          bankLogs: arrayofBankLogs
        })
      }

    }
    if (logs.status === 200) {
      const arrayOfInletLogs = (logs.data.data || [])
        .reduce((sum, item) => {
          if (sum.find((inletLogs) => inletLogs.inlet_id === item.inlet_id)) {
            return sum.map((s) =>
              s.inlet_id === item.inlet_id
                ? {
                    ...s,
                    items: [item, ...s.items],
                  }
                : {
                    ...s,
                    items: s.items,
                  }
            );
          } else {
            return [
              ...sum,
              {
                inlet_id: item.inlet_id,
                items: [item],
              },
            ];
          }
        }, [])
        .sort((a, b) => a.inlet_id.localeCompare(b.inlet_id));
      this.setState({
        inletLogs: arrayOfInletLogs.map((inletObj) => {
          return {
            ...inletObj,
            lineFrequencyMax: Math.max(
              ...inletObj.items.map((item) => item.lineFrequency)
            ),
            lineFrequencyMin: Math.min(
              ...inletObj.items.map((item) => item.lineFrequency)
            ),
          };
        }),
      });
    }
  };
  async componentDidMount() {
    this.grabDataSet();
    // if (!this.props.banksLoading && !this.props.banksLoaded) {
      this.props.fetchResource("banks", (err, banks) => {
        
      },`?enclosureSerialNumber=${this.props.match.params.enclosureSerialNumber}`);
    // }
  }
  render() {
    const { enclosureSerialNumber } = this.props.match.params;
    const { inlets, banks, inletsLoading, inletsLoaded } = this.props.resources;
    const { inletLogs, bankLogs } = this.state;
    const foundDevice =
      this.props.resources.devices.find(
        (d) => d.enclosureSerialNumber == enclosureSerialNumber
      ) || {};


    const labels = inletLogs[0]
      ? inletLogs[0].items.map((i) => i.createdAt)
      : [];
    const dataset = (field, items, itemId, customLabelFunc) =>
      items.map((logSetObj, index) => {
        return {
          label: customLabelFunc ? customLabelFunc(logSetObj) : logSetObj[itemId],
          data: logSetObj.items.map((i) => i[field]),
          borderColor: chartColors.primaryColors[index % 8],
          backgroundColor: chartColors.primaryColorHalfOpacity[index % 8],
        };
      });
    return (
      <div>
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="cell large-6">
            {inlets.length > 0 ? (
              inlets[0].inletPhase === "Three Phase" ? (
                <RowList
                  rows={[
                    {
                      label: "Inlet Stats",
                      labelStyle: {
                        fontWeight: "bold",
                        fontSize: "110%",
                      }
                    },
                    {
                      label: "Energy Consumption",
                      value: inlets[0].energyAccumulation,
                      unit: "kWh",
                    },
                    {
                      label: "Line 1 Current RMS",
                      value:
                        (
                          (inlets[0].inletLines || []).find(
                            (l) => l.line === "L1"
                          ) || {}
                        ).currentRms !== undefined
                          ? (
                              (inlets[0].inletLines || []).find(
                                (l) => l.line === "L1"
                              ) || {}
                            ).currentRms.toFixed(2)
                          : "-",
                    },
                    {
                      label: "Line 2 Current RMS",
                      value:
                        (
                          (inlets[0].inletLines || []).find(
                            (l) => l.line === "L2"
                          ) || {}
                        ).currentRms !== undefined
                          ? (
                              (inlets[0].inletLines || []).find(
                                (l) => l.line === "L2"
                              ) || {}
                            ).currentRms.toFixed(2)
                          : "-",
                    },
                    {
                      label: "Line 3 Current RMS",
                      value:
                        (
                          (inlets[0].inletLines || []).find(
                            (l) => l.line === "L2"
                          ) || {}
                        ).currentRms !== undefined
                          ? (
                              (inlets[0].inletLines || []).find(
                                (l) => l.line === "L2"
                              ) || {}
                            ).currentRms.toFixed(2)
                          : "-",
                    },
                  ]}
                />
              ) : (
                inlets.map((inlet, inletIndex) => {
                  return (
                    [
                    <RowList
                      key={inletIndex}
                      rows={[
                        {
                          label: inlets.length > 0 ? `Inlet ${inletIndex + 1}` : "Inlet Stats",
                          labelStyle: {
                            fontWeight: "bold",
                            fontSize: "110%",
                          },
                        },
                        {
                          label: "Inlet ID",
                          value: `${inlet.id}`,
                        },
                        {
                          label: "Current RMS",
                          value: <DataValue value={`${inlet.inletCurrentRms !== undefined ? `${inlet.inletCurrentRms.toFixed(2)}` : ""}`} unit="A" />
                          // value: 
                          //       `${inlet.inletCurrentRms !== undefined
                          //           ? `${inlet.inletCurrentRms.toFixed(2)} A`
                          //           : "-"
                          //       }`
                        },
                        {
                          label: "Voltage RMS",
                          value: <DataValue value={inlet.inletVoltageRms !== undefined
                                ? `${inlet.inletVoltageRms.toFixed(2)}`
                                : "-"} unit="V" />
                        },
                        {
                          label: "Line Frequency",
                          value: <DataValue value={inlet.inletLineFrequency !== undefined ? `${inlet.inletLineFrequency.toFixed(2)}` : "-"} unit="Hz" /> 
                        },
                        {
                          label: "Power Factor",
                          value: <DataValue value={inlet.inletPowerFactor !== undefined ? `${inlet.inletPowerFactor.toFixed(2)}` : "-"} unit="" />
                               
                        },
                        {
                          label: "Energy Consumption",
                          value: <DataValue value={inlet.inletEnergyAccumulation !== undefined ? `${inlet.inletEnergyAccumulation.toFixed(2)}` : "-"} unit="kWh" />
                        },
                        {
                          label: "Active Power",
                          value: <DataValue value={inlet.inletActivePower !== undefined ? `${inlet.inletActivePower.toFixed(2)}` : "-"} unit="W" />
                                // `${
                                //   inlet.inletActivePower !== undefined
                                //     ? `${inlet.inletActivePower.toFixed(2)} W`
                                //     : "-"
                                // }`
                        },
                        {
                          label: "Apparent Power",
                          value: <DataValue value={inlet.inletApparentPower !== undefined ? `${inlet.inletApparentPower.toFixed(2)}` : "-"} unit="VA" />
                                // `${
                                //   inlet.inletApparentPower !== undefined
                                //     ? `${inlet.inletApparentPower.toFixed(2)} VA`
                                //     : "-"
                                // }`
                        },
                      ]}
                    />,
                    <div style={{ marginBottom: "15px "}} />
                    ]
                    
                  )
                })
              )
            ): (
              inletsLoading ? (
                <RowList
                  rows={[{
                    label: "",
                    value: <div className="skeleton-blank" />
                  },{
                    label: "",
                    value: <div className="skeleton-blank" />
                  },{
                    label: "",
                    value: <div className="skeleton-blank" />
                  },{
                    label: "",
                    value: <div className="skeleton-blank" />
                  }]}                   
                />
              ) : (
                null
              )
            )}
            <br />
            {banks.length > 0 && (
              inlets[0] && (
                inlets[0].inletPhase === "Three Phase" ? (
                  <RowList
                    rows={[{
                      label: "Circuit Stats",
                      labelStyle: {
                        fontWeight: "bold",
                        fontSize: "110%",
                      }
                    }, {
                      label: "Circuits",
                      value: banks.map((bank) => `${bank.bankLines}`).join(", "),
                    }, {
                      label: "Current RMS",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.currentRms !== undefined
                                ? `${bank.currentRms.toFixed(2)} A`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Voltage RMS",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.voltageRms !== undefined
                                ? `${bank.voltageRms.toFixed(2)} V`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Line Frequency",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.lineFrequency !== undefined
                                ? `${bank.lineFrequency.toFixed(2)} Hz`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Power Factor",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.powerFactor !== undefined
                                ? `${bank.powerFactor.toFixed(2)}`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Energy Consumption",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.energyAccumulation !== undefined
                                ? `${bank.energyAccumulation.toFixed(
                                    2
                                  )} kWh`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Active Power",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.activePower !== undefined
                                ? `${bank.activePower.toFixed(2)} W`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Apparent Power",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.apparentPower !== undefined
                                ? `${bank.apparentPower.toFixed(2)} VA`
                                : "-"
                            }`
                        )
                        .join(", "),
                    }]}
                  />
                ) : (
                  null
                )
              )
            )}
          </div>
          {inlets.length > 0 && (
            <div className="cell large-6" style={{ }}>
              <RowList
                rows={[
                  {
                    label: "Inlet Information",
                    labelStyle: {
                      fontWeight: "bold",
                      fontSize: "110%",
                    },
                  },

                  {
                    label: "Enclosure Serial number",
                    value: inlets[0].enclosureSerialNumber,
                  },
                  {
                    label: "Inlet Plug",
                    value: inlets[0].inletPlug,
                  },
                  {
                    label: "Inlet Type",
                    value:
                      inlets[0].inletType === "dual"
                        ? "Dual Circuit"
                        : inlets[0].inletType === "ats"
                        ? "ATS (Automatic Transfer Switch)"
                        : "Standard",
                  },
                  {
                    label: "Inlet Phase",
                    value: inlets[0].inletPhase,
                  },
                  {
                    label: "Connection State",
                    value: inlets[0].connectionState,
                  },
                  {
                    label: "Last Updated",
                    value: foundDevice.lastUpdated && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {formatDistanceStrict(
                            foundDevice.lastUpdated,
                            new Date()
                          )}{" "}
                          ago
                        </span>
                        {foundDevice.loading ? (
                          <span
                            style={{
                              // cursor: "pointer",
                              paddingLeft: "7px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Loading black />
                          </span>
                        ) : (
                          <span
                            title="Refresh PDU Information"
                            className="black"
                            style={{
                              cursor: "pointer",
                              paddingLeft: "7px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              this.props.getDeviceItemAndData(
                                foundDevice.enclosureSerialNumber
                              );
                            }}
                          >
                            <i
                              style={{ fontWeight: "600", color: "#6c6f72" }}
                              className="material-icons"
                            >
                              sync
                            </i>
                          </span>
                        )}
                      </div>
                    ),
                    valueStyle: {
                      fontStyle: "italic",
                    },
                  },
                ]}
              />
            </div>
          )}
          <div className="cell large-12">
            <div
              className="div"
              style={{
                marginBottom: "20px",
                marginTop: "40px",
                fontSize: "110%",
                fontWeight: "600",
              }}
            >
              Input Monitoring Logs
            </div>
            <DateRangeInput
              onDateChange={(firstDate, secondDate, text) => {
                this.setState(
                  {
                    chartStartDate: firstDate,
                    chartEndDate: secondDate,
                    chartTimeFrameText: text,
                  },
                  () => {
                    this.grabDataSet();
                  }
                );
              }}
              onExport = {() => {
                this.grabCsvData();
              }}
              firstDate={this.state.chartStartDate}
              endDate={this.state.chartEndDate}
              firstDataPointDate={
                inletLogs[0] ? inletLogs[0].items[0].createdAt : null
              }
              lastDataPointDate={
                inletLogs[0]
                  ? inletLogs[0].items[inletLogs[0].items.length - 1].createdAt
                  : null
              }
            />
          </div>
          {this.state.inletLogsLoading && !this.state.inletLogsLoaded && (
            <React.Fragment>
            <div className="cell large-6">
              <div className="app-item" style={{
                height: "300px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Loading black />
              </div>
            </div>
            <div className="cell large-6">
              <div className="app-item" style={{
                height: "300px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Loading black />
              </div>

            </div>
            </React.Fragment>
          )}
          {[
            {
              wrapperClass: "large-auto",
              disabled:
                !inletLogs[0] || (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Current RMS over time",
              dataField: "currentRms",
            },
            {
              disabled: !foundDevice.inletEnergySupported || !inletLogs[0],
              title: "Inlet Energy Consumption over time",
              dataField: "energyAccumulation",
            },
            {
              disabled:
                !foundDevice.inletEnergySupported ||
                !inletLogs[0] ||
                (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Voltage RMS over time",
              dataField: "voltageRms",
            },
            {
              disabled:
                !foundDevice.inletEnergySupported ||
                !inletLogs[0] ||
                (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Power Factor over time",
              dataField: "powerFactor",
            },
            {
              disabled:
                !foundDevice.inletEnergySupported ||
                !inletLogs[0] ||
                (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Line Frequency over time",
              dataField: "lineFrequency",
              scales: {
                x: {
                  type: "time",
                },
                y: {
                  suggestedMax: (inletLogs[0] || {}).lineFrequencyMax * 1.001,
                  suggestedMin: (inletLogs[0] || {}).lineFrequencyMin * 0.999,
                  ticks: {
                    // padding: 100
                  },
                },
              },
            },
            {
              disabled:
                !foundDevice.inletEnergySupported ||
                !inletLogs[0] ||
                (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Active Power over time",
              dataField: "activePower",
            },
          ].map((item) => {
            if (item.disabled) return null;
            return (

            <LineChart
              key={item.title}
              item={item}
              chartTimeFrameText={this.state.chartTimeFrameText}
              labels={labels}
              datasets= {dataset(item.dataField, inletLogs, "inlet_id")}
            />
            )
            // return (
            //   <div
            //     key={item.title}
            //     className={item.wrapperClass || "cell large-6"}
            //   >
            //     <div className="app-item">
            //       <Line
            //         options={{
            //           animation: {
            //             duration: 0,
            //           },
            //           elements: {
            //             point: { radius: 0 },
            //           },
            //           responsive: true,
            //           plugins: {
            //             legend: {
            //               position: "top",
            //             },
            //             title: {
            //               display: true,
            //               text: item.title,
            //             },
            //           },
            //           scales: {
            //             x: {
            //               type: "time",
            //               time: {
            //                 unit:
            //                   this.state.chartTimeFrameText === "last-week"
            //                     ? "day"
            //                     : undefined,
            //                 displayFormats: {
            //                   day: "dd MMM",
            //                 },
            //               },
            //             },
            //             y: {
            //               suggestedMax: 0.1,
            //             },
            //             ...(item.scales || {}),
            //           },
            //         }}
            //         data={{
            //           labels,
            //           datasets: dataset(item.dataField, inletLogs, "inlet_id"),
            //         }}
            //       />
            //     </div>
            //   </div>
            // );
          })}
          {inlets[0] && inlets[0].inletPhase === "Three Phase" &&
            <LineChart
              item={{
                title: "Three Phase Lines Current RMS over time",
              }}
              chartTimeFrameText={this.state.chartTimeFrameText}
              labels={labels}
              datasets= {[
                {
                  line: "L1",
                  field: "threePhaseLine1CurrentRms",
                },
                {
                  line: "L2",
                  field: "threePhaseLine2CurrentRms",
                },
                {
                  line: "L3",
                  field: "threePhaseLine3CurrentRms",
                },
              ].map((phase, ind) => ({
                label: phase.line,
                data: (inletLogs[0] || { items: [] }).items.map(
                  (i) => i[phase.field]
                ),
                borderColor: chartColors.primaryColors[ind % 8],
                backgroundColor:
                  chartColors.primaryColorHalfOpacity[ind % 8],
              }))}
            />
          }
          {inlets[0] && inlets[0].inletPhase === "Three Phase" &&
            [{
              title: "Circuits Current RMS over time",
              dataField: "currentRms",
            }, {
              title: "Circuits Voltage RMS over time",
              dataField: "voltageRms",
            }, {
              title: "Circuits Line Frequency RMS over time",
              dataField: "lineFrequency",
            }, {
              title: "Circuits Power Factor over time",
              dataField: "powerFactor",
            }, {
              title: "Circuits Active Power over time",
              dataField: "activePower",
            }, {
              title: "Circuits Energy Accumulation over time",
              dataField: "energyAccumulation",
            }].map(item => (
              <LineChart
                key={item.title}
                item={item}
                chartTimeFrameText={this.state.chartTimeFrameText}
                labels={labels}
                datasets={dataset(
                  item.dataField, 
                  bankLogs, 
                  "bank_id",
                  (logObj) => (banks.find(b => b.id == logObj.bank_id) || {}).bankLines || logObj.bank_id
                )}
              />
            ))
          }
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }
}



const DataValue = ({ value, unit }) => {
  return (
    <span style={{ display: "flex", alignItems: "flex-end" }}>
      <span
        style={{
          fontWeight: "600",
          fontSize: "105%",
        }}
      >
        {value}
      </span>
      <span
        style={{
          fontSize: "80%",
          paddingLeft: "4px",
          paddingBottom: "2px",
        }}
      >
        {unit}
      </span>
    </span>
  );
};
