import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
import DeviceOutlets from "../deviceOutlets";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
);

export default class DevicePageOutlets extends React.Component {
  state = {
    outletLogs: [],
    chartStartDate: (() => {
      const todaysUnixStartTime = new Date();
      todaysUnixStartTime.setHours(0, 0, 0, 0);
      return (
        todaysUnixStartTime.getTime()
      )
    })(), // default to last day
    chartEndDate: Date.now(),
    chartTimeFrameText: "Today",
    chartTimeOffset: 0,
    calendarView: false
  };
  async componentDidMount() {
    // this.grabDataSet();
  }
  render() {
    const { foundDevice } = this.props;
    return foundDevice.outletPwrMeasurementsSupported ||
      foundDevice.outletSwitchingSupported ? (
      <React.Fragment>
        <div style={{padding: "10px"}}></div>
        <DeviceOutlets 
          {...this.props}
          device={foundDevice}
        />
        {/* <div className="grid-x grid-padding-x">
          <div className="cell">
            <div className="app-item">
              <Line
                options={{
                  animation: {
                    duration: 0,
                  },
                  elements: {
                    point: {
                      radius: 0.1 ,
                      hitRadius: 20,
                    },
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top",
                      // display: false
                    },
                    tooltip: {
                      
                    },
                    title: {
                      display: true,
                      text: "Current RMS over time",
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        // unit: "day",
                      },
                    },
                    y: {
                      suggestedMax: 0.5,
                    },
                  },
                }}
                data={{
                  // labels: this.state.outletLogs.map((log) => log.items[0].createdAt),
                  labels: this.state.outletLogs[0] ? this.state.outletLogs[0].items.map(i => i.createdAt) : [],
                  datasets:  this.state.outletLogs.map((logSetObj, index) => {
                    return {
                      label: logSetObj.outlet_id,
                      data: logSetObj.items.map((i) => i.currentRms),
                      borderColor: chartColors.primaryColors[index % 8],
                      backgroundColor: chartColors.primaryColorHalfOpacity[index % 8],
                      tension: 0.2
                    };
                  })
                }}
              />
            </div>
          </div>
        </div> */}
        
      </React.Fragment>
    ) : null;
  }
}
