import React from 'react';
import fetchWrapper from './components/fetchWrapper';
import SideHeader from './components/common/sideHeader/sideHeader';
import Loading from './components/common/loading';
import ActivePageContainer from './pages/routingContainer';

class App extends React.Component {
  state = {
    miniModeactive: false,
    mobileSideNavActive: false
  }
  changeAppState = (newState) => {
    this.setState(newState);
  }
  render() {
    // console.log(this.props)
    // const {
    //   user,
    //   fetchResource,
    //   modifyResource,
    //   history,
    //   location,

    // } = this.props;

    return (
      <div key="main-section" className={`main-section ${this.state.miniModeActive ? "mini" : ""}`}>
        <SideHeader
          miniModeActive={this.state.miniModeActive}
          mobileSideNavActive={this.state.mobileSideNavActive}
          changeAppState={this.changeAppState}
          // user={user}
        />
        {/* <Loading
          fixed
          black
          size="40px"
        /> */}
        <ActivePageContainer
          mobileSideNavActive={this.state.mobileSideNavActive}
          changeAppState={this.changeAppState}
          {...this.state}
          {...this.props}
        />
      </div>
    );
  }
}



export default fetchWrapper(App);
