import axios from "axios";
import React from 'react';
// import TextInput from "../../components/common/inputs/textInput";
// import formatDistance from 'date-fns/formatDistance'; // import formatDistance for date formatting
import format from 'date-fns/format'; // import format for date formatting

export default class UsersBilling extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // You can add some local state variables here, if needed
        };
    }
    updatePlan = () => {
        axios.post(`/v1/billing`, {
            flow_data: {
                type: 'subscription_update',
                subscription_update: {
                    subscription: this.props.user.billing.subscriptionId
                }
            }
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            console.log(res)
            window.open(res.data.data.url, "_blank")
        }).catch(err => {
            console.log(err)
        })
    }
    cancelPlan = () => {

        axios.post(`/v1/billing`, {
            flow_data: {
                type: 'subscription_cancel',
                subscription_cancel: {
                    subscription: this.props.user.billing.subscriptionId
                }
            }
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            console.log(res)
            // window.location.href = res.data.data.url;
            window.open(res.data.data.url, "_blank")
        }).catch(err => {
            console.log(err)
        })
    }
    renewPlan = () => {
        axios.post(`/v1/billing`, {
            flow_data: {
                // type: 'payment_method_update',
            },
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            console.log(res)
            window.open(res.data.data.url, "_blank")
        }
        ).catch(err => {
            console.log(err)
        }
        )

    }
    updatePaymentMethod = () => {
        axios.post(`/v1/billing`, {
            flow_data: {
                type: 'payment_method_update',
            },
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            console.log(res)
            window.open(res.data.data.url, "_blank")
        }
        ).catch(err => {
            console.log(err)
        }
        )

    }

    render() {
        const user = this.props.user;
        const {
            planName,
            planDeviceCount,
            planPricePerMonthString,
            planWillRenew,
            planRenewDate,
            currentPeriodStart,
            currentPeriodEnd,
            subscriptionStatus,
            invoiceHistory = [],
        } = user.billing;
        return (
            <div className="grid-container">
                <div className="grid-x grid-padding-x grid-padding-y">

                    <div className="cell large-6 ">
                        <div>
                            <h3>Current Plan</h3>
                        </div>
                        <div className="app-item" style={{ padding: "30px" }}>
                            {planName ? <div>
                                <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: "20px" }}>

                                    <span
                                        style={{
                                            backgroundColor: subscriptionStatus === "active" ? "rgb(98 171 125)" : "rgb(159 159 159)",
                                            color: "white",
                                            borderRadius: "3px",
                                            padding: "5px 7px",
                                            textTransform: "capitalize",

                                        }}
                                    >
                                        {subscriptionStatus}
                                    </span>
                                    <span style={{ color: "grey", marginLeft: "20px" }}>{format(new Date((currentPeriodStart || 1) * 1000), 'MMMM d')}-{format(new Date((currentPeriodEnd || 1) * 1000), 'MMMM d, yyyy')}</span>

                                </div>

                                <h5 style={{ marginTop: "10px" }}>
                                    <span>{planName}</span>
                                    <span style={{ color: "grey", marginLeft: "5px" }}>({planDeviceCount} devices)</span>
                                </h5>
                                <h5><b><div style={{ marginTop: "10px", marginBottom: "10px" }}>{planPricePerMonthString}</div></b></h5>
                                <div style={{ marginTop: "10px" }}>
                                    {planWillRenew ? `Renews on ${format(new Date((planRenewDate || 1) * 1000), 'MMMM d')}` : `Cancels ${format(new Date((planRenewDate || 1) * 1000), 'MMMM d')}`}
                                </div>
                                <div style={{ marginTop: "25px" }}>
                                    <div
                                        className="button"
                                        onClick={() => {
                                            this.renewPlan();
                                        }}
                                        style={{
                                            marginRight: "10px"
                                        }}
                                    >
                                        Manage Subscription
                                    </div>

                                    {planWillRenew ?
                                        <div
                                            className="button"
                                            onClick={() => {
                                                this.cancelPlan();
                                            }}
                                            style={{
                                                backgroundColor: "white",
                                                border: "solid #223647 1px",
                                                color: "#223647",
                                                marginRight: "10px"
                                            }}
                                        >
                                            Cancel plan
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div> : (
                                <div>
                                    {/* <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: "20px" }}>
                                        <span
                                            style={{
                                                backgroundColor: "rgb(159 159 159)",
                                                color: "white",
                                                borderRadius: "3px",
                                                padding: "5px 7px",
                                                textTransform: "capitalize",

                                            }}
                                        >
                                            No plan
                                        </span>
                                    </div> */}
                                        <div>
                                            <div
                                                style={{
                                                    paddingBottom: "20px",
                                                }}
                                            >Gain access and support for Synaccess Cloud plans</div>
                                            <a href="https://synaccess.com/contact" target="_blank">
                                                <div className="button">
                                                    Contact
                                                </div>
                                            </a>
                                        </div>
                                </div>
                            )}
                        </div>

                        {/* <div>

                            <div
                                style={{
                                    marginTop: "50px"
                                }}
                            >
                                <h3>Payment Method</h3>
                            </div>
                            <div className="app-item" style={{ }}>
                                <PaymentMethod
                                    cardBrand={customerPaymentMethodbrand}
                                    last4Digits={customerCCLastFour}
                                    expMonth={customerCCExpMonth}
                                    expYear={customerCCExpYear}
                                    updatePaymentMethod={this.updatePaymentMethod}
                                />
                            </div>
                        </div> */}
                    </div>
                    {invoiceHistory && invoiceHistory.length > 0 &&
                        <div className="cell large-6 ">
                            <div>
                                <h3>Invoices</h3>
                            </div>
                            <div className="app-item" style={{ padding: "20px", paddingTop: "15px" }}>
                                <InvoiceList invoices={invoiceHistory} />
                            </div>
                        </div>
                    }

                </div>
            </div>
        );
    }
}

class InvoiceList extends React.Component {
    render() {
        const { invoices } = this.props;
        return (
            <div>
                <div style={{ display: "flex", marginBottom: "10px", borderBottom: "solid rgba(0,0,0,0.1) 1px", paddingBottom: "5px" }}>
                    <div style={{
                        flex: "3",
                        fontWeight: "bold",
                    }}>
                        Status
                    </div>
                    <div style={{
                        flex: "2",
                        fontWeight: "bold",
                    }}>
                        Amount
                    </div>
                    <div style={{
                        flex: "6",
                        fontWeight: "bold",
                    }}>

                    </div>
                    <div style={{
                        flex: "4",
                        fontWeight: "bold",
                    }}>
                        Date
                    </div>
                    <div style={{
                        flex: "2",
                        fontWeight: "bold",
                    }}>

                    </div>
                </div>
                {invoices.map((invoice, index) => (
                    <div key={index} style={{ display: "flex", alignItems: "center", paddingBottom: "5px", borderBottom: "solid rgba(0,0,0,0.1) 1px", marginBottom: "5px" }}>
                        <div style={{ flex: "3", display: "flex", alignItems: "center", }}>
                            <div
                                style={{
                                    // backgroundColor: invoice.status === "paid" ? "rgb(98, 171, 125)" : "rgb(153,153,153)",
                                    // color: "white",
                                    color: invoice.status === "paid" ? "rgb(98, 171, 125)" : "rgb(153,153,153)",

                                    padding: "5px 10px",
                                    borderRadius: "3px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >

                                {invoice.status === "paid" ? (
                                    <i className="material-icons" style={{ marginRight: "0px " }}>
                                        check
                                    </i>
                                ) : (
                                    <i className="material-icons" style={{ marginRight: "0px " }}>
                                        error
                                    </i>
                                )}
                                <span
                                    style={{
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {invoice.status}
                                </span>

                            </div>
                        </div>
                        <div style={{ flex: "2" }}>{(invoice.invoiceAmount / 100).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}</div>
                        <div style={{ flex: "6" }}>{invoice.productName || "N/A"}</div>
                        <div style={{ flex: "4" }}>{format(new Date(invoice.invoiceDate * 1000), 'MMMM d, yyyy')}</div>
                        <div style={{ flex: "2", display: 'flex', alignItems: 'center', color: "rgba(0,0,0,0.7)" }}>
                            <a
                                href={invoice.invoiceLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: "rgba(0,0,0,0.7)",
                                    textDecoration: "none",
                                }}
                            >

                                <i className="material-icons">download</i>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

class PaymentMethod extends React.Component {
    render() {
        const { cardBrand, last4Digits, expMonth, expYear } = this.props;
        const hasPaymentMethod = cardBrand && last4Digits && expMonth && expYear;
        return (
            <div>
                {hasPaymentMethod &&
                    <div
                        style={{
                            padding: '15px',
                            borderRadius: '8px',
                            border: "solid rgba(0,0,0,0.1) 1px",
                            // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            backgroundColor: '#ffffff',
                        }}
                    >
                        <div
                            style={{
                                flex: '0 0 36px',
                                height: '36px',
                                backgroundColor: '#eeeeee',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span
                                className="material-icons"
                                style={{
                                    // fontSize: '18px',
                                    color: '#444444',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {cardBrand === 'visa' ? 'payment' : 'credit_card'}
                            </span>
                        </div>
                        <div>
                            <p
                                style={{
                                    margin: '0',
                                    // fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: '#333333',
                                }}
                            >
                                {(cardBrand || "").toUpperCase()}
                            </p>
                            <p
                                style={{
                                    margin: '0',
                                    // fontSize: '12px',
                                    color: '#666666',
                                }}
                            >
                                **** **** **** {last4Digits}
                            </p>
                            <p
                                style={{
                                    margin: '0',
                                    // fontSize: '12px',
                                    color: '#666666',
                                }}
                            >
                                Expires: {expMonth}/{expYear}
                            </p>
                        </div>

                    </div>
                }
                <div
                    className="button"
                    onClick={this.props.updatePaymentMethod}
                    style={{
                        // backgroundColor: "white",
                        // border: "solid #223647 1px",
                        // color: "#223647",
                        marginTop: "16px",
                        marginLeft: "15px"
                    }}
                >
                    {hasPaymentMethod ? "Update payment method" : "Add Payment Method"}
                </div>
            </div>
        );
    }
}

