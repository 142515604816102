import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ActivePageHeader from '../common/topHeader/topHeader';
import ActivePageSubHeader from '../common/topHeader/subHeader';
import BreadCrumbs from '../common/breadCrumbs';

export default class PageTemplate extends React.Component {
  render() {
    const {
      headerTitle,
      headerIcon,
      sections,
      defaultPath,
      basePath,
      component,
      // deviceLoaded,
      unlisted,
      prevPath,
      mobileSideNavActive,
      changeAppState
    } = this.props;
    
    return (
      <div className="active-page-wrapper">
        <div className="active-page-header">
          <ActivePageHeader
            title={headerTitle}
            icon={headerIcon}
            mobileSideNavActive={mobileSideNavActive}
            changeAppState={changeAppState}
          />
          {(sections) &&
            <ActivePageSubHeader
              sections={sections.filter(s => !s.disabled)}
            />
          }
        </div>
        <div className="active-page-container">
          <div className="active-page-container-content">
            <Switch>
              {sections && sections.map(section => {
                if (section.disabled) return null;
                
                return (
                  <Route
                    key={section.label}
                    path={section.path}
                    render={() => {
                      // const CompToRender = scrollTopOnMount(section.component);
                      const CompToRender = section.component;
                      return (
                        <CompToRender {...this.props} />
                      );
                    }}
                  />
                )
              })}
              {component &&
                <Route
                  path={basePath}
                  render={({ match, history, location }) => {
                    // const CompToRender = scrollTopOnMount(component);
                    const CompToRender = component;
                    return ([
                      unlisted && 
                        <BreadCrumbs
                          match={match}
                          key="goback"
                          history={history}
                          location={location}
                          prevPath={prevPath}
                        />,
                      <CompToRender {...this.props} key="comptorender" />
                    ])
                  }}
                />
              }
              {(defaultPath && basePath) && 
                <Redirect from ={basePath} to={defaultPath} />
              }
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}