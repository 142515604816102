import React from 'react';
import Logo from '../../synaccess_white.svg';

export default class PendingApproval extends React.Component {
  render() {
    return (
      <div className="welcome-background">
        <div className="grid-container">

        <div className="grid-x grid-padding-x grid-padding-y">
            <div className="cell" style={{
              display: 'flex', justifyContent: 'flex-end'
            }}>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: "solid white 1px",
                  padding: '10px 30px',
                  borderRadius: '3px',
                  color: "white",
                  cursor: 'pointer',
                  // borderBottom: "solid white 1px"

                }}
                onClick={() => {
                  this.props.signout()
                }}
              >
                <i className="material-icons">logout</i>
                <span>
                  <b>
                    Sign Out
                  </b>
                </span>
              </div>
            </div>
          </div>
          <div className="grid-x grid-padding-x grid-padding-y align-center"  style={{ marginTop: "0vh" }}>
            <div
                className="cell large-5 medium-7 small-10"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >             
               <div
                style={{
                  padding: "0px",
                  marginTop: "10vh",
                  borderRadius: "2px",
                  width: "100%"
                }}
              >
                <div
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <img alt="logo" src={Logo} width="165px" />
                </div>
                <div
                  style={{
                    boxShadow: "3px 3px 20px rgba(0,0,0,0.05)",
                    width: "100%",
                    backgroundColor: "white",
                    padding: "50px 40px",
                    display: 'flex',
                    // alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: "column",
                    // textAlign: 'center',
                    borderRadius: "3px"
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <i className="material-icons" style={{ fontSize: "100px", width: "100px"}}>approval</i>
                  </div>
                  <div
                    style={{
                      paddingBottom: "20px",
                      paddingTop: "20px",
                      borderRadius: "2px",
                      fontSize: "125%",
                      fontWeight: "600",
                    }}
                  >
                    We're evaluating your profile
                  </div>
                  <div style={{
                    // textAlign: 'start'
                  }}>
                    Expect a response in 1-2 business days. Feel free to reach out to fast track your request or ask any question. 
                  </div>
                  <div>

                    <a
                      href="http://synaccess.com/contact"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className="button"
                        style={{
                          marginTop: "30px",
                          // marginBottom: "40px",
                          color: "#333",
                          backgroundColor: "white",
                          border: "solid #333 1px"
                        }}
                      >
                        Contact Us For Questions
                      </div>
                    </a>
                  </div>
                </div>
                {/* <div
                  style={{
                    padding: "20px 10px 10px 10px",
                    color: "white",
                  }}
                >
                  Need Help? {" "}
                  <Link to="/contact">
                    <b style={{ color: "white" }}>Contact Us</b> 
                  </Link>
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}