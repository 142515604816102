import React from 'react';
import eventRefs from '../../assets/eventRef';
import { Link } from "react-router-dom";

export default class DeviceAlerts extends React.Component {
  componentDidMount() {
    // console.log(this.props)
    // this.props.fetchResource("actions")
  }
  render() {
    const {
      events,
      devices
    } = this.props.resources;



    const sortedEvents = [
      ...events.filter(e => e.triggered),
      ...events.filter(e => !e.triggered)
    ]
    const columnWidths = [
      9, //0
      18, //1
      8, //2
      6, //3
      6, //4
      6, //5 
      9, //6
      12, //7
      4 //8
    ];
    return (
      <div
        style={{
          overflowX: "auto"
        }}
      >
        {this.props.match.params.enclosureSerialNumber ? (
          <div className="">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: "10px"
              }}
            >
              <Link
                to={`/devices/connected/${this.props.match.params.enclosureSerialNumber}/alerts/new`}
              >
                <div
                  className="button"
                  style={{
                    marginBottom: "0px"
                  }}
                >
                  <span>+ Create New Alert Event</span>
                </div>

              </Link>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: "10px"
            }}
          >
            <div>
              <select
                style={{
                  marginBottom: "0px"
                }}
                onChange={(e) => {
                  if (e.target.value) {
                    this.props.history.push(`/devices/connected/${e.target.value}/alerts/new`)
                  }
                }}
              >
                <option value="">Create New Alert for Device</option>
                {devices.map((device, index) => {
                  return (
                    <option
                      key={index} 
                      value={device.enclosureSerialNumber}
                      disabled={device.connectionState === "Disconnected" ? true : false}
                    >
                      {device.deviceName === "SynLink PDU" ||
                        !device.deviceName
                        ? `PDU SN:${device.enclosureSerialNumber}`
                        : device.deviceName}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        )}
        <div className="grid-row header">
          {/* <div style={{ flex: columnWidths[0] }}>Category</div> */}
          <div style={{ flex: columnWidths[1] }}>Event Name</div>
          <div style={{ flex: columnWidths[2] }}>Status</div>
          <div style={{ flex: columnWidths[3] }}>Parameter 1</div>
          <div style={{ flex: columnWidths[4] }}>Parameter 2</div>
          <div style={{ flex: columnWidths[5] }}>Parameter 3</div>
          {/* <div style={{ flex: columnWidths[6] }}>Actions</div> */}
          <div style={{ flex: columnWidths[7] }}>PDU</div>
          {/* <div style={{ flex: columnWidths[8] }}></div> */}
        </div>
        {this.props.resources.eventsLoading ? (
          <Loading columnWidths={columnWidths} />
        ) : (
          sortedEvents.length === 0 ? (
            <div className="grid-row-wrapper">
              <div className="grid-row">
                <div style={{ paddingLeft: "30px" }}>
                  No Events Found
                </div>
              </div>
            </div>
          ) : (
            null
          )
        )}
        {sortedEvents.map((event, index) => {
          const foundEventRef = (eventRefs.find(er => er.code === event.code) || {});
          const firstParamUnit = ((foundEventRef.params || [])[0] || {}).unit
          const secondParamUnit = ((foundEventRef.params || [])[1] || {}).unit
          const thirdParamUnit = ((foundEventRef.params || [])[2] || {}).unit
          const eventRefName = foundEventRef.name;

          const device = devices.find(d => d.enclosureSerialNumber == event.enclosureSerialNumber) || {};

          return (
            <div key={index} className="grid-row-wrapper">
              <div
                className="grid-row"
                style={{
                }}
              >
                <div
                  style={{
                    flex: columnWidths[1],
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Link
                    to={`/devices/connected/${device.enclosureSerialNumber}/alerts/${event.id}`}
                    // to={`/devices/connected/${device.enclosureSerialNumber}/alerts`}
                  >
                    <React.Fragment>
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                        }}
                      >

                        <span
                          style={{ fontWeight: '600', textDecoration: "underline" }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span>
                              {eventRefName}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                            </span>
                          </span>
                        </span>
                        <span>
                          {event.name}
                        </span>

                      </span>
                    </React.Fragment>
                  </Link>
                </div>

                <div style={{ flex: columnWidths[2] }} title={event.triggered ? "ALERT" : "OK"}>

                  <div className="spreadsheet-badge" style={{ backgroundColor: event.triggered ? "rgb(235 210 210)" : "rgb(194, 223, 194)" }}>
                    <i className="material-icons" style={{ color: event.triggered ? "maroon" : "forestgreen", marginRight: "0px" }}>
                      {event.triggered ? "circle_notifications" : "task_alt"}
                    </i>
                    <b className="show-for-large-only">{event.triggered ? "Alert" : " OK  "}</b>
                  </div>
                  {/* <div>
                    {event.triggered && event.numTicksBeyond &&
                      <span style={{ color: "gray", fontSize: "0.8em" }}>
                        {event.numTicksBeyond} ticks beyond
                      </span>
                    }
                  </div> */}
                </div>
                <div style={{ flex: columnWidths[3] }}>{(event.params || [])[0] || "-"} {firstParamUnit}</div>
                <div style={{ flex: columnWidths[4] }}>{(event.params || [])[1] || "-"} {secondParamUnit}</div>
                <div style={{ flex: columnWidths[5] }}>{(event.params || [])[2] || "-"} {thirdParamUnit}</div>
                {/* <div style={{ flex: columnWidths[6], whiteSpace: "pre-line" }}>{event.actions.map(a => a).join(',\n')}</div> */}
                <div style={{ flex: columnWidths[7] }}>
                  <Link
                    to={`/devices/connected/${device.enclosureSerialNumber}`}
                  >
                    <span>
                      {device.deviceName === "SynLink PDU" ||
                        !device.deviceName
                        ? `PDU SN:${device.enclosureSerialNumber}`
                        : device.deviceName}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

const Loading = ({ columnWidths }) => {
  return (
    <div style={{}}>
      {[null, null, null, null].map((item, index) => {
        return (
          <div className="grid-row-wrapper" key={index}>
            <div className="grid-row">
              {/* <div style={{ flex: columnWidths[0] }}></div> */}
              <div style={{ flex: columnWidths[1] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[2] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[3] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[4] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[5] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[6] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[7] }}>
                <div className="skeleton-blank" />
              </div>
              {/* <div style={{ flex: columnWidths[8] }}>
              <div className="skeleton-blank" />
            </div> */}
              {/* <div style={{ flex: columnWidths[9] }}>
                <div className="skeleton-blank" />{" "}
              </div> */}
            </div>
          </div>
        );
      })}
    </div>
  )
}