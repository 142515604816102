
const chartColors = {
  primaryColors: [
    "#4a69a8",
    "#bd5e39",
    "#42996e",
    "#a8894a",
    "#cf4152",
    "#3849c7",
    "#c7c238",
    "#9e4bad",
  ],
  primaryColorHalfOpacity: [
    "rgba(74, 105, 168,0.4)",
    "rgba(189, 94, 57, 0.4)",
    "rgba(66, 153, 110, 0.4)",
    "rgba(168, 137, 74, 0.4)",
    "rgba(207, 65, 82, 0.4)",
    "rgba(56, 73, 199, 0.4)",
    "rgba(199, 194, 56, 0.4)",
    "rgba(158, 75, 173, 0.4)",
  ],
  secondaryColors: ["#cf4152", "#3849c7", "#c7c238"],
  combo1: ["#c77324", "#c224c7", "#29c724"],
  combo2: ["#cf4152", "#3849c7", "#c7c238"],
  combo3: ["#cc5b3c", "#7b34c7", "#80c734"],
};

export default chartColors;